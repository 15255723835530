// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sWLXxND3v9HNLAnLnRzH.eLnh2MjrD4kQQCd9iPWp fieldset{border:1px solid var(--text-secondary);border-radius:8px}.sWLXxND3v9HNLAnLnRzH.eLnh2MjrD4kQQCd9iPWp div:hover input:not(:focus)+fieldset{border-color:var(--btn-outlined-hover)}.sWLXxND3v9HNLAnLnRzH.eLnh2MjrD4kQQCd9iPWp input{border:0;color:var(--text-primary);padding-top:12px;padding-bottom:12px}.sWLXxND3v9HNLAnLnRzH.eLnh2MjrD4kQQCd9iPWp input::placeholder{color:var(--text-secondary);font-weight:500}`, "",{"version":3,"sources":["webpack://./src/ui/TextFieldComponent/TextFieldComponent.module.scss"],"names":[],"mappings":"AACE,oDACE,sCAAA,CACA,iBAAA,CAGF,gFACE,sCAAA,CAGF,iDACE,QAAA,CACA,yBAAA,CACA,gBAAA,CACA,mBAAA,CAEA,8DACE,2BAAA,CACA,eAAA","sourcesContent":[".wrapper.textField {\n  fieldset {\n    border: 1px solid var(--text-secondary);\n    border-radius: 8px;\n  }\n\n  div:hover input:not(:focus) + fieldset {\n    border-color: var(--btn-outlined-hover);\n  }\n\n  input {\n    border: 0;\n    color: var(--text-primary);\n    padding-top: 12px;\n    padding-bottom: 12px;\n\n    &::placeholder {\n      color: var(--text-secondary);\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `sWLXxND3v9HNLAnLnRzH`,
	"textField": `eLnh2MjrD4kQQCd9iPWp`
};
export default ___CSS_LOADER_EXPORT___;

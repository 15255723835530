// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VpgLLmBr8Bp49lnH3k97{height:100%;flex:1;display:flex;flex-direction:column;row-gap:"0px";overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/features/Conversations/ChatsTreeSearch.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,MAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  height: 100%;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  row-gap: '0px';\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `VpgLLmBr8Bp49lnH3k97`
};
export default ___CSS_LOADER_EXPORT___;

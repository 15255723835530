// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KBAlzdO3Un4Csg5UYX9L{height:100vh;display:grid;grid-template-columns:minmax(250px, 340px) minmax(650px, 1fr)}@media(width >= 2400px){.KBAlzdO3Un4Csg5UYX9L{grid-template-columns:minmax(250px, 500px) minmax(650px, 1fr)}}`, "",{"version":3,"sources":["webpack://./src/pages/ChatLayoutPage/ChatLayoutPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,6DAAA,CAEA,wBALF,sBAMI,6DAAA,CAAA","sourcesContent":[".wrapper {\n  height: 100vh;\n  display: grid;\n  grid-template-columns: minmax(250px, 340px) minmax(650px, 1fr);\n\n  @media (width >= 2400px) {\n    grid-template-columns: minmax(250px, 500px) minmax(650px, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `KBAlzdO3Un4Csg5UYX9L`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t1Ms6RQscmyekBQPluNQ.vnsgFLD9c4aofWVrVLir{min-width:264px;height:45px;margin-bottom:5px}.t1Ms6RQscmyekBQPluNQ.vnsgFLD9c4aofWVrVLir input{border:0;color:#000;padding-top:11px;padding-bottom:11px}.t1Ms6RQscmyekBQPluNQ.vnsgFLD9c4aofWVrVLir input::placeholder{color:#aeaeae;font-family:var(--font-family-main);font-weight:500;font-size:16px}`, "",{"version":3,"sources":["webpack://./src/ui/TextFieldAuthComponent/TextFieldAuthComponent.module.scss"],"names":[],"mappings":"AAAA,2CACE,eAAA,CACA,WAAA,CACA,iBAAA,CAEA,iDACE,QAAA,CACA,UAAA,CACA,gBAAA,CACA,mBAAA,CAEA,8DACE,aAAA,CACA,mCAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".wrapper.textField {\n  min-width: 264px;\n  height: 45px;\n  margin-bottom: 5px;\n\n  input {\n    border: 0;\n    color: #000000;\n    padding-top: 11px;\n    padding-bottom: 11px;\n\n    &::placeholder {\n      color: #aeaeae;\n      font-family: var(--font-family-main);\n      font-weight: 500;\n      font-size: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `t1Ms6RQscmyekBQPluNQ`,
	"textField": `vnsgFLD9c4aofWVrVLir`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pofq8mD4vihYaseXebnQ{display:flex;flex-direction:column;row-gap:17px;padding:25px 18px;border-right:1px solid var(--border-primary);overflow-y:hidden;height:100vh}.Pofq8mD4vihYaseXebnQ .c8rML8_zdVXYrdV8uvrw{margin-top:auto;display:flex;flex-direction:column;row-gap:17px}.Pofq8mD4vihYaseXebnQ .dF1ciBAjmxfHST0VedmC{stroke:#fff}`, "",{"version":3,"sources":["webpack://./src/features/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,iBAAA,CACA,4CAAA,CACA,iBAAA,CACA,YAAA,CAEA,4CACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CAGF,4CACE,WAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  row-gap: 17px;\n  padding: 25px 18px;\n  border-right: 1px solid var(--border-primary);\n  overflow-y: hidden;\n  height: 100vh;\n\n  .bottomItems {\n    margin-top: auto;\n    display: flex;\n    flex-direction: column;\n    row-gap: 17px;\n  }\n\n  .newChatIcon {\n    stroke: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Pofq8mD4vihYaseXebnQ`,
	"bottomItems": `c8rML8_zdVXYrdV8uvrw`,
	"newChatIcon": `dF1ciBAjmxfHST0VedmC`
};
export default ___CSS_LOADER_EXPORT___;

import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import { Sidebar } from '@/features/Sidebar';

import styles from './ChatLayoutPage.module.scss';

//embaixo de {Children} botar <ApiSettings /> se quiser
export const ChatLayoutPage = ({ children }: PropsWithChildren) => (
  <div className={classNames(['app', styles.wrapper])}>
    <Sidebar />
    {children}
  </div>
);
